.box-pref {
  border: 1px solid black;
  border-radius: 3%;
  padding: 10px;
  box-shadow: inset 0px 0px 5px 5px rgba(99, 95, 95, 0.297);
  margin-inline: 5px;
  height: 214px;
  max-height: 214px;
  overflow: auto;
}

.up_down_btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.up-down {
  display: flex;
  width: fit-content !important;
  flex-direction: column;
  justify-content: center;
}