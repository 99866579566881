.ulnav {
  color: white;
  font-size: 22px;
  cursor: pointer;
  list-style-type: none;
}

.searchstyle {
  width: 183px;
  margin-left: 8px;
  margin: 17px;
  height: 33px;
  border: 2, solid, blue;
  border-radius: 10px;
}

.fa-arrow-left {
  cursor: pointer !important;
  ;
}

.inp_grp {
  display: flex;
  margin-left: -13px;
}

.searchbtnnn {
  background: blue;
  width: 49px;
  height: 35px;
  margin-top: 16px;
  margin-left: -16px;
  border-radius: 7px;
  color: white;
  cursor: pointer;
}

.val {
  font-family: var(--primary-font);
  display: contents;
}

.activeResult {
  background-color: rgb(125, 116, 217) !important;
}

.mod-type {
  padding: 0px 5px;
  margin-left: 5px;
  border-radius: 3px;
  vertical-align: middle;
}

.val {
  display: contents;
}

.activeResult {
  background-color: rgb(125, 116, 217) !important;
}

.mod-col1 {
  background-color: #b3b3ff;
}

.mod-col2 {
  background-color: #ffbf80;
}

.mod-col3 {
  background-color: #b3ff66;
}

.mod-col4 {
  background-color: #ff6699;
}

.mod-col5 {
  background-color: #ffff33;
}

.mod-col6 {
  background-color: #5e66ab;
}

.mod-col7 {
  background-color: #aa67b8;
}

.mod-col8 {
  background-color: #62c1a6;
}

.mod-col9 {
  background-color: #e3b76b;
}

.mod-col10 {
  background-color: #62c1a6;
}

.mod-col11 {
  background-color: #abda55;
}

.mod-col12 {
  background-color: #e0a854;
}

.mod-col13 {
  background-color: #51e8ab;
}

.mod-col14 {
  background-color: #7e72eb;
}

.mod-col15 {
  background-color: #b31010;
  color: white;
}

.mod-set {
  float: right;
}

.side-navbar {
  /* width: 247px; */
  height: 100%;
  position: fixed;
  margin-left: -300px;
  background-color: #100901;
  transition: 0.5s;
  border-radius: 8px;
}

.nav-link:active,
.nav-link:focus,
.nav-link:hover {
  background-color: #ffffff26;
}

.my-container {
  transition: 0.4s;
}

.active-nav {
  margin-left: 0;
}

.bg-color {
  background-color: #e0e0e0;
  padding: 0px 1rem;
}

/* for main section */
.active-cont {
  margin-left: 180px;
}

#menu-btn {
  background-color: #100901;
  color: #fff;
  margin-left: -62px;
}

.obj_head {
  font-size: 20px;
  text-align: center;
  font-weight: bold;
}

.csm_btn {
  line-height: 1.4em;
  width: auto;
  border-radius: 5px;
  font-size: var(--primary-btn-size);
  font: var(--primary-font);
}

.csm_btn_pri {
  color: var(--primary-btn-color);
  background-color: var(--primary-btn-bg-color);
  border: 1px solid var(--primary-btn-bg-color);
}

.sub-btn {
  margin-left: 0.5em;
}

body {
  background-color: #fbfbfb;
}

@media (min-width: 991.98px) {
  main {
    padding-left: 240px;
  }
}

/* Sidebar */
.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  padding: 58px 0 0;
  /* Height of navbar */
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 5%), 0 2px 10px 0 rgb(0 0 0 / 5%);
  width: 240px;
  z-index: 600;
}

@media (max-width: 991.98px) {
  .sidebar {
    width: 100%;
  }
}

.sidebar .active {
  border-radius: 5px;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
}

.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 48px);
  padding-top: 0.5rem;
  overflow-x: hidden;
  overflow-y: auto;
  /* Scrollable contents if viewport is shorter than content. */
}

.li_left {
  cursor: pointer;
  background: #8000ff;
  color: #fff;
  font-weight: 400;
  border-bottom: 1px solid #fff;
}

.ul_left {
  border-right: 3px solid #fff;
  border-radius: 0px;
}

.buttn,
.buttnp {
  float: right;
  border: none;
  background: #bbb;
  border-radius: 2px;
  padding: 0px 10px;
}

.navpointer {
  font: var(--primary-font);
  line-height: 1.5;
  padding-left: 2em;
  cursor: pointer;
}

.navpointer:hover {
  font-weight: 600;
}

.appm {
  font-family: var(--primary-font);
  background: var(--primary-top-bar) !important;
  color: #fff !important;
  letter-spacing: 0.5px;
}

.sec {
  display: flex;
  font-family: var(--primary-font);
  background: #ccc;
  padding: 0.2em 0em 0.2em 0.5em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  letter-spacing: 0.5px;
}

.flri {
  float: right;
}

.fllf {
  float: left;
}

.buttn {
  padding: 0 10px;
}

.buttnp {
  padding: 0 8px;
}

.arrow_sty {
  border: none;
  background: #bbb;
  border-radius: 2px;
  padding: 4px 6px;
  margin-right: 5px;
}

.lfnavserach {
  border-radius: 10px;
  /* height: 2em; */
  width: 80% !important;
}

.search-button {
  background: transparent;
  border: none;
  outline: none;
  margin-left: -33px;
}

.search-block {
  display: flex !important;
  justify-content: center !important;
  flex-direction: row;
  /* box-shadow: -3px 5px 5px grey; */
  z-index: 100;
  padding: 5px;
  /* position: fixed; */
  background: darkgray;
  /* width: 18.6em; */
}

.search-ic {
  border-radius: 10px;
  margin-left: -35px;
  padding-top: 10px;
}

.left_icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 5px;
  background: #000;
  color: #fff;
  margin: 0px 2px;
  height: 100%;
  text-align: center;
  margin-top: 3px;
}

.arr_width {
  padding: 0px 0px 3px 0px;
  margin: 0px 2px;
  width: 10% !important;
  justify-content: center;

}

.cp {
  cursor: pointer;
}

.ovr {
  overflow: overlay;
}

.pagesetupleft {
  height: 95vh;
  overflow: auto;
}

.list-group-item {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  border-top: none;
  border-right: none;
  border-left: none;
}

.list-group {
  background: #ddd;
}