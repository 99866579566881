.submenu {
    position: absolute;
 /*    top: 0; */
    left: 100%;
    background-color: white;
    border: 1px solid #ccc;
    display: flex;
    flex-direction: column;
    width: 200px;
  }
  
  .submenu-item {
    /* padding: 5px; */
    cursor: pointer;
  }