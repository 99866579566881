.ldata_mrg {
    margin-left: 18rem;
    }
  
  .mrg_nam{
      margin-right: 1.3rem;
  }  
  
  .txt_wdth{
      width: 50%;
  }