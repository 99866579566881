.owner-select{
    width: 496px;
    margin: 5px 5px;
    height: 37px;
    border-radius: 5px;
    margin-top: 40px;
    margin-right: 31rem;

}
.ccccc{
border:2px;
border-radius:6px;
margin-top: 13px;
background: linear-gradient(to right, rgb(127, 127, 213), rgb(134, 168, 231), rgb(145, 234, 228));



}