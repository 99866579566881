.parent-div{
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.card-width-manual{
    width: 340px!important;
}
.txt-white{
    color: white;
}
.bg-auth-1 {
    background-color: var(--primary-auth-theme-1) !important;
    color: white;
}

.bg-auth-2 {
    background-color: var(--primary-auth-theme-2) !important;
    color: white;
}
.btn-width{
    width: 8em!important;
}

.inp-passwordd {
    position: relative;
}

.inp-pass-eye {
    position: absolute;
    color: black;
    right: 10px;
    top: 4px;
}
.edge-browser input[type="password"]::-ms-reveal {
    display: none;
}
.text-sml {
    font-size: 15px !important;
}

.inpptt {
    border-radius: 5px !important;
    width: 100%;
    height: 36px;
    padding: 14px;
    border: 2px solid black;
}
.manual-checkbox{
    width: 14px;
    height: 14px;
}
.inpptt-select {
    border-radius: 5px !important;
    height: 36px;
}

.hvr-yellow:hover {
    color: yellow !important;
}

.maincss {
    color: white;
    font-family: sans-serif;
    border-radius: 8px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    width: 100%;
    max-width: 1000px;
    height: fit-content;
}
.d-gridd {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 0px 15px;
}
.useric {
	text-align: center;
	font-size: 90px;
}
@media only screen and (max-height:712px) {
	.maincss{
		margin: 150px 0px;
	}
}
@media only screen and (max-width:768px) {
	.maincss{
		width: 100%;
	}
	.d-gridd {
		grid-template-columns: 1fr;
	}
}