.select{
    width: 500px;
    height: 49px;
    margin: 2rem;
}

.inpttt{
    width: 500px;
    height: 49px;
    margin: 2rem;
    
}
.cardcss {
    border: 2px solid white;
    border-radius: 7px;
    height: auto;
    /* background: linear-gradient(to right, rgb(127, 127, 213), rgb(134, 168, 231), rgb(145, 234, 228)); */
    background-color: #cccccc;
}