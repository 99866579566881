.header {
    background: darkblue;
    color: white;
    /* height: 5vh; */
    justify-content: space-between;
    display: flex;
  }

  .mfadmin{
    color: white;
    font-size: 22px;
    font-family: sans-serif;
  }
  
  .homeicon {
    font-size: var(--primary-font-size1-5);
    cursor: pointer;
    color: var(--primary-heading);
  }
  
  .homeicon_font {
    font-size: var(--primary-font-size1-4);
  }
  
  .usericon {
    font-size: var(--primary-font-size1-2);
    cursor: pointer;
    padding-left: 8px;
    color: var(--primary-heading);
  }
  
  .u-name {
    font-size: var(--primary-font-size1-3);
    font-family: initial;
    margin-right: 10px;
    color: var(--primary-heading);
  }

  .u-name1 {
    font-size: var(--primary-font-size1-3);
    font-family: initial;
    margin-right: 10px;
    color: var(--primary-heading);
  }

  @media only screen and (max-width: 768px) {
    .u-name1 {
      display: none;
      font-size: 18px;
    }
  }
  
  .home_top {
    position: relative;
    top: -2px;
  }
  
  .mainTopBar {
    font: var(--primary-font);
  }
  
  .disfl {
    display: flex;
  }
  
  .backic {
    cursor: pointer;
  }
  
  .fl1 {
    flex: 1;
  }
  
  .no_pad {
    padding: 0px;
  }
  
  .primary_clr {
    color: var(--primary-heading);
  }
  
  .arrow_icon {
    font-size: var(--primary-font-size1-3);
  }
  
  .mainbgcolor {
    background: var(--primary-top-bar);
    padding: 4px 4px 0px 4px;
  }
  
  #r_icon > * {
    margin-left: 5px;
    margin-right: 5px;
  }
  
  .logouticon {
    font-size: 26px;
    margin-left: 35px;
    cursor: pointer;
  }
  
  .marleft10 {
    margin-left: 2em;
  }
  .marright10 {
    margin-right: 2em;
}
  
  .rig {
    float: right;
    padding-right: 10px;
  }
  
  .span-cnt {
    position: relative;
    padding-right: 5px;
    padding-left: 5px;
    border-radius: 5px;
    right: 20px;
    top: -12px;
    width: 2px;
    font-size: small;
    background-color: red;
  }
  
  .openNav {
    font-size: 22px;
    display: flex;
    margin-inline: 5px;
    /* margin-top: 10px; */
    /* width: 60px; */
    /* background: darkgray; */
    /* height: 26px; */
    /* border-radius: 12px; */
    /* margin-left: -7px; */
  }
  .icons {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* .img-wrap{
      position: relative;
      width: 200px;
      height: 200px;
      overflow: hidden;
      border-radius: 50%;
    }
  
  .img-upload:before{
      content: "\f093";
      font-size: 90px;
      position: absolute;
      padding-top: 80px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      color: #63d3a6;
      width: 200px;
      height: 200px;
      border-radius: 50%;
      opacity: 0;
      transition: .5s ease;
      background-color: #fff;
    }
    .img-upload:hover:before{
     opacity: 1;
    }
    img {
      width: auto;
      height: 100%;
    } */
  .f-row {
    display: flex;
    flex-direction: row;
  }
  
  .imgggg {
    width: 102px;
    height: 100px;
    object-fit: cover;
    border-radius: 60px;
  }

  .ReactModal__Overlay--after-open {
    position: fixed !important;
    inset: 0px !important;
    background-color:rgb(33 33 33 / 75%) !important;
    /* filter: blur(3px); */
    z-index: 102;
  }

.opmodal{
  position: fixed;
  inset: 0px;
  /* background-color: rgba(255, 255, 255, 0.75); */
  filter: blur(3px);

}

@media only screen and (max-width: 768px) {
  .g-overlay {
    position: absolute;
    top: 38px;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
  }
  .g-blur {
    filter: blur(3px);
    /* transition: filter 0.3s ease; */
  }
}
  