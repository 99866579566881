.hhh{
    font-size: 26px;
    text-align: initial;
    margin-left: 22px;
    margin-top: 6px;
}

.ttt{
    font-size: 18px;
    margin-top: 36px;
    margin-left: 59px;
}

.select{
    width: 500px;
    height: 49px;
    margin: 2rem;
}

.inpttt{
    width: 407px;
    height: 39px;
    margin-left: 11px;
    border-radius: 7px;
    
}

.inpttt2{
    width: 526px;
    height: 39px;
    margin-left: 13px;
    border-radius: 7px;
    margin-top: 29px;
    
}

.inpcheckbox{
    margin-top: 37px;
    width: 19px;
    margin-left: 8px;
}

.divvvvvcheck{
    display: inline-flex;
    margin-bottom: 9px;
    margin-left: -167px;
}
.submitbtnnnnnn{
    width: 1217px;
    height: 37px;
    margin-top: 21px;
    background: darkgreen;
    color: white;
    border-radius: 9px;
    align-items: center;
    justify-content: center;
    text-align: center;
}