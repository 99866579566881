.cl_small {
	flex: 0 0 auto;
	padding-right: 0 !important;
	width: 25% !important;
	border-right: 0.5px solid #ddd;
	/* border: 1px solid rgba(0, 0, 0, 0.125); */
	height: 100%;
	/* overflow-y: scroll; */
  }
  
  .cl_close {
	flex: 0 0 auto;
	width: 0 !important;
	border: 0;
	height: 0px !important;
	/* overflow-y: scroll; */
  }
  .cl_big {
    flex: 0 0 auto;
    width: 75% !important;
    padding-left: 0 !important;
    padding: 0px;
    display: flex;
    max-height: 96vh;
    /* overflow: auto; */
}
  
  /* .cl_big {
	text-align: center;
	flex: 0 0 auto;
	width: 75% !important;
	padding-left: 0 !important;
	padding: 0px;
	display: flex;
  } */
  
  .fr {
	--bs-gutter-x: 0 !important;
  }
  
  .cl_bigClose {
	text-align: center;
	flex: 0 0 auto;
	width: 100% !important;
	padding: 0px;
	height: 100vh;
	display: flex;
  }
  
  .pagesetup {
	/* height: 95vh; */
	/* overflow-y: auto; */
	flex-grow: 1;
	/* overflow-x: auto; */
  }
  
  .cl_no {
	display: none;
  }
  
  .cl_yes {
	flex: 0 0 auto;
	width: 100%;
  }
  
  .left_icon_zero {
	position: absolute;
	left: 0%;
	width: fit-content;
	padding: 5px 3px;
	border-bottom-right-radius: 5px;
	border-top-right-radius: 5px;
	background: #000;
	color: #fff;
  }
  
  .maintopbar {
	padding-left: 20px;
  }
  .lfnavserach {
	height: 40px;
	border-radius: 10px;
  }
  
  .homepadd {
	padding-left: 12px;
  }
  
  /* .homeicon {
	  font-size: 30px;
	  cursor: pointer;
	  padding-left: 10px;
  } */
  
  .refreshic {
	font-size: 25px;
	cursor: pointer;
	padding-left: 13px;
  }
  
  .ovr {
	overflow: overlay;
  }
  
  .backic {
	cursor: pointer;
  }
  
  .homeic {
	color: #fff;
  }
  
  .mainbgcolor {
	background: rgb(10, 47, 212);
	padding-top: 4px;
  }
  
  .navpadd {
	display: flex;
	flex-direction: row;
	height: 100vh;
	width: 100%;
  }
  
  .dashpadd {
	margin-left: 296px;
  }
  
  @media only screen and (max-width: 800px) {
	.cl_small {
	  width: 75% !important;
	  z-index: 100;
	  transform: translateX(0);
	  /* left:0!important; */
	  transition: transform 0.5s;
	}
  
	.cl_close {
	  flex: 0 0 auto;
	  width: 75% !important;
	  z-index: 100;
	  border: 0;
	  height: 0px !important;
	  transform: translateX(-100%);
	  /* left: -75%!important; */
	  transition: transform 0.5s;
	  /* overflow-y: scroll; */
	}
	.cl_big {
	  position: absolute;
	  width: 100% !important;
	  /* height: 100%; */
	  /* overflow-y: scroll!important; */
	}
  }
  
  .mndtrytrue {
	color: red;
	font-size: 0.7em;
	position: relative;
	bottom: 0.2em;
	margin-right: 0.5em;
  }
  
  .mndtryfalse {
	color: darkgrey;
	font-size: 0.7em;
	position: relative;
	bottom: 0.2em;
	margin-right: 0.5em;
  }
  
  @media (max-width: 768px) {
	.row {
	  margin: 0px !important;
	}
  }
  

  

  .usernoti_outerline {
	border: 1px solid #ddd;
	/* margin: 10px 10px 0px 10px; */
	border-radius: 3px;
	/* padding: 5px; */
	padding: 20px 22px;
  }

  .curcoolor{
	cursor: pointer;
	color: cornflowerblue;
  }
  