.company-logo-section{
    margin-top: -78px;
    margin-top: -2px;
    margin-bottom: 8px;
    border-bottom: 2px solid;
}
.prnt-div {
width: 10.8in;
width: 7in;
/* min-width: 3.8in; */
min-height: 6in; 
/* max-width: 5.8in; */
margin: 0 auto;
padding: 3mm 6mm 3mm 3mm;
background: white;
font-family: Arial Veranda, Helevetica, Avant Garde
}

.address-padding{
/* width:35%; */
/* border: 2px solid #000000; */
padding: 10px;
}

.prnt-ch-1 {
transform: scale(1.04548, 1.04548);
}

.inv-table {
width: 100%;
border: 2px solid black;
}

.inv-tr {
border: 2px solid black;
}

.inv-td {
border-right: 2px solid black;
text-align: center;
}

.inv-img {
width: calc(3.8in/2.1);
font-size: 4.7mm;
font-weight: bold;
}

.inv-span {
width: calc(173.714px);
font-size: 4.7mm;
font-weight: bold;
display: none;
}

.inv-del-img {
width: calc(3.8in/2.1);
}

.inv-barcode {
max-height: 18mm;
overflow: hidden;
width: 3.6in;
}

.p-onemm {
padding: 1mm;
}

.inv-barcode-svg {
transform: translate(0, 0);
display: block;
margin: auto;
}

.s-fill-black {
fill: #000000;
}

.s-fill-white {
fill: #ffffff;
}

.txttt {
font: 11px monospace
}
.inv-txttt-bold{
font:bold 13px monospace
}

.td-fw-bld {
font-weight: bold;
padding: 1mm;
}

.inv-adrs-table {
width: 100%;
border: 2px solid black;
border-top: none;
}

.inv-td-saa {
border-right: 2px solid black;
padding: 1mm;
font-size: 3.7mm;
}

.inv-fw-bold {
font-weight: bold;
}

.inv-sto-name {
text-transform: uppercase;
font-weight: bold;
font-size: initial;
}

.inv-m-4 {
margin: 4mm;
}

.inv-tab-3 {
width: 100%;
border: 2px solid black;
border-top: none;
font-size: 3.7mm;
border-collapse: collapse;
}

.inv-td-3-p-1 {
border-right: 2px solid black;
padding: 1mm;
}

.inv-td-prod-det {
border: 2px solid black;
padding: 1mm;
}

.inv-border-2 {
border: 2px solid black;
}

.inv-tr-azdf {
border: 2px solid black;
height: 0.8in;
max-height: 0.8in
}

.inv-adjdslds {
border: 2px solid black;
width: 15%;
}

.inv-br-2 {
border-right: 2px solid black;
}

.inv-adaloqwei {
padding: 1mm;
border-bottom: 2px solid black;
max-width: calc(3.8in - 8mm);
}