
.abc {
    background-color: green;
    cursor: move;
    top: 5;
    left: 5;
    display: flex;
    justify-content: center;
    /* align-items: center; */
    /* transform:0.2 linear translateY(); */
    /* transition: top 1ms, left 1ms; */
    /* position: absolute; */
  }
  .abc2 {
    background-color: yellow;
    cursor: move;
    top: 5;
    left: 5;
    display: flex;
    justify-content: center;
    /* align-items: center; */
    /* transform:0.2 linear translateY(); */
    /* transition: top 1ms, left 1ms; */
    /* position: absolute; */
  }
  .abc3 {
    background-color: orange;
    cursor: move;
    top: 5;
    left: 5;
    display: flex;
    justify-content: center;
    /* align-items: center; */
    /* transform:0.2 linear translateY(); */
    /* transition: top 1ms, left 1ms; */
    /* position: absolute; */
  }
  .abc4 {
    /* border: 1px solid black; */
    cursor: move;
    top: 5;
    left: 5;
    /* display: flex; */
    /* justify-content: center; */
    /* width: 300px;
    height: 200px;
    align-items: center; */
    /* align-items: center; */
    /* transform:0.2 linear translateY(); */
    /* transition: top 1ms, left 1ms; */
    /* position: absolute; */
  }
  .zak {
  }
  .um {
  }
  .ay {
  }
  .ad {
  }
  /* .gggc{
    transform: translateY(50);
    transition: top 20ms linear;
  } */
  
  
  .exp {
    /* display: flex;
    justify-content: center;
    align-items: center; */
    background-color: red;
    width: 100px;
    height: 100px;
    align-self: center;
    position: absolute;
    z-index: 1000;
  }
  .exp:focus {
    border: 2px black solid;
  }
  /* .hov{
  }
  .hov:hover{
    border:;
  } */
  
  #out {
    margin-left: auto;
    /* margin-top: -87px; */
  }
  
  .main-container{
    margin-top: 2em;
    display: flex;
    /* flex-flow: row wrap; */
  }
  
  .grdview{
    /* margin-right: 63em; */
    font-size: 19px;
    font-family: sans-serif;
  }