#colm2,
#colm1 {
  border: 1px solid black;
  border-radius: 5px;
  padding: 10px;
  box-shadow: inset 0px 0px 5px 5px rgba(99, 95, 95, 0.297);
}

#colmA2,
#colmA1 {
  border: 1px solid black;
  border-radius: 5px;
  padding: 10px;
  box-shadow: inset 0px 0px 5px 5px rgba(99, 95, 95, 0.297);
}
#colmB2,
#colmB1 {
  border: 1px solid black;
  border-radius: 5px;
  padding: 10px;
  box-shadow: inset 0px 0px 5px 5px rgba(99, 95, 95, 0.297);
}

.box-scl {
  max-height: 417px;
  overflow: auto;
}

.selected-rowA{
  background-color: rgb(45, 73, 216);
}

.selected-rowB{
  background-color: rgb(45, 73, 216);
}
.current-field {
  font-weight: 500;
  font-size: 1.1rem;
}

.greenLab {
  color: green;
}

.blackLab {
  color: black;
}

.formviewbackground {
  border: 2px solid white;
  border-radius: 7px;
  background: rgb(6, 34, 66);
  color: white;
  font-size: 23px;
  /* background: linear-gradient(to right, rgb(127, 127, 213), rgb(134, 168, 231), rgb(145, 234, 228)); */
}

.heading {
  /* text-align: center; */
  font-weight: bold;
  font-size: large;
}

.ryt-left-btn {
  text-align: center;
  border: 1px solid #bbb;
  border-radius: 3px;
  font-size: 20px;
  padding: 4px 10px;
  margin-left: 5px;
}

.roooww {
  width: 496px;
  margin: 5px 5px;
  height: 37px;
  border-radius: 5px;
  margin-top: 40px;
  margin-right: 31rem;
}

.mndtryfals {
  color: white;
}

.mndtrytru {
  color: red;
}

.ccc:after {
  color: white;
}

.formViewBack {
  border: 2px solid white;
  border-radius: 7px;
  /* background: rgb(6, 34, 66);
    color: white; */
  font-size: 23px;
  /* background: linear-gradient(to right, rgb(127, 127, 213), rgb(134, 168, 231), rgb(145, 234, 228)); */
}

.up-down-view {
  transform: rotate(-90deg);
  text-align: center;
  border: 1px solid #bbb;
  border-radius: 3px;
  font-size: 20px;
  padding: 4px 10px;
  margin-left: 5px;
}

.up-down-dis {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.wdt-lg {
  width: 80vw !important;
}

.wdt-sm {
  width: 10vw !important;
}

.roooww {
  width: 496px;
  margin: 5px 5px;
  height: 37px;
  border-radius: 5px;
  margin-top: 40px;
  margin-right: 31rem;
}

.mndtryfals {
  color: white;
}

.mndtrytru {
  color: red;
}

.ccc:after {
  color: white;
}

.view_btn {
  border: 1px solid #bbb;
  border-radius: 3px;
  font-size: 20px;
  margin-bottom: 5px;
  padding: 4px 10px;
}

.heading {
  font-family: var(--primary-font);
  font-size: var(--primary-font-size);
}

.pref_mar {
  margin: 5px 0px;
}

.disfl {
  display: flex;
}

#colm2,
#colm1 {
  border: 1px solid black;
  border-radius: 5px;
  padding: 10px;
  box-shadow: inset 0px 0px 5px 5px rgba(99, 95, 95, 0.297);
  height: 60vh;
  overflow-y: auto;
}