/* .main {
	width: 330px;
	height: 330px;
	border: 1px solid #ccc;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
	border-radius: 50%;
	background-color: black;
}

.circle {
	width: 30px;
	height: 30px;
	background: cyan;
	border-radius: 50%;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	overflow: hidden;
	margin: auto;
	animation: circle 6s linear infinite;
}

@keyframes circle {
	0% {
		transform: rotate(0deg) translate(-165px) rotate(0deg);
	}
	100% {
		transform: rotate(360deg) translate(-165px) rotate(-360deg);
	}
} */

.loader {
	border: 16px solid #f3f3f3; /* Light grey */
	border-top: 16px solid #3498db; /* Blue */
	border-radius: 50%;
	width: 80px;
	height: 80px;
	animation: spin 2s linear infinite;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
