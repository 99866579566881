.inpt_typefil_ref {
  /* border-top-right-radius: 4px; */
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  /* border-bottom-right-radius: 4px; */
  padding: 5px;
  border: 1px solid;
  height: 1.9rem;
  font-size: 16px;
}
.btnsrc_fil {
  background: #fff;
  padding: 3px 10px;
  border: 1px solid;
  border-left: 0px;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  color: #000;
}
.inpu_rad {
  border-radius: 4px;
  margin-top: 2px;
}
.inpt_typefil {
  /* border-top-right-radius: 4px; */
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  /* border-bottom-right-radius: 4px; */
  padding: 5px;
  border: 1px solid;
  font-size: 16px;
}
.selected-row {
  background-color: var(--bs-primary)!important;
  color: #fff;
}

.ref_multircd {
  padding: 2px 5px;
  list-style-type: none;
  cursor: pointer;
  color: black;
  background: white;
  border-radius: 3px;
  min-width: 192px;
  position: absolute;
  border: 1px solid #ddd;
  z-index: 1;
}

#clm2,
#clm1 {
  border: 1px solid black;
  border-radius: 3%;
  padding: 10px;
  box-shadow: inset 0px 0px 5px 5px rgba(99, 95, 95, 0.297);
}
.columnarray2 {
  cursor: pointer;
}
.selct_optfil {
  border-radius: 4px;
  font-size: 15px;
  min-width: 130px;
  padding: 5px 5px 5px 12px;
}

.padbtnnn {
  display: flex;
  font-size: medium;
  flex-direction: row;
  justify-content: center;
}

.martop {
  margin-top: 5px;
  align-items: center;
}

/* .btnsrc_fil {
  background: #fff;
  padding: 5px 10px;
  border: 1px solid;
  border-left: 0px;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  color: #000;
} */

.pdrt10 {
  padding-right: 10px;
}

.scrl {
  overflow-x: auto;
}

.pdrt10 {
  padding-right: 10px;
}

.pdlf10 {
  padding-left: 10px;
}

.pdtp5 {
  padding-top: 5px;
}

.fntSz15 {
  font-size: 15px;
}

.cancelbtn {
  font-size: 1.2em;
}


@media only screen and (max-width:768px){

  .filteroverflow{
    overflow-x: auto;
  }
  
}
