.puis-v2fl5pkubaqu126k6zseo6li6q .puis-image-overlay-grey, .puis-v2fl5pkubaqu126k6zseo6li6q .s-image-overlay-grey {
    overflow: hidden;
}
.puis-v2fl5pkubaqu126k6zseo6li6q .puis-spacing-small {
    margin-bottom: 8px !important;
}
.puis-v2fl5pkubaqu126k6zseo6li6q .s-padding-right-small {
    padding-right: 8px !important;
}
.puis-v2fl5pkubaqu126k6zseo6li6q .s-padding-left-small {
    padding-left: 8px !important;
}
.puis-v2fl5pkubaqu126k6zseo6li6q .s-product-image-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.puis-v2fl5pkubaqu126k6zseo6li6q .s-text-center {
    text-align: center;
}
.puis-spacing-small {
    margin-bottom: 8px !important;
}
.a-relative, .aok-relative {
    position: relative;
}
.s-matching-dir {
    direction: ltr;
}
/* .puis-v2fl5pkubaqu126k6zseo6li6q .puis-image-overlay-grey:after, .puis-v2fl5pkubaqu126k6zseo6li6q .s-image-overlay-grey:after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.1;
    pointer-events: none;
} */
.puis-image-overlay-grey:after, .s-image-overlay-grey:after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: black;
    opacity: 0.05;
    pointer-events: none;
}
img{
    /* border: 1px solid grey; */
}