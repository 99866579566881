.inVisibleField {
  font-size: 13px;
  text-align: center;
  margin-bottom: 0px;
  /*  font-weight: bold; */
}
.multiUpdateParent {
  background-color: white;
  border: 1px solid;
  border-radius: 6px;
  align-items: center;
  justify-content: center;
  /* max-width: 75%; */
}
.multiUpdateParent div div div{
  display: flex;
  align-items: center;
}
.multiUpdateParent div div div input{
  border: 2px solid black;
}
.multiUpdateBtn1 {
  background-color: white;
  border: 1px;
  margin: 3px;
  margin-left: 20px;
  margin-right: 0px;
}

.multiUpdateBtn2 {
  background-color: white;
  border: 1px;
  margin: 3px;
  margin-left: 0px;
}

.selected {
  background-color: rgba(0, 0, 0, 0.25) !important;
}

.list-images {
  height: 80px;
  width: 100%;
  object-fit: contain;
}

.heading_top {
  font-size: 40;
  text-align: center;
  font-style: oblique;
  border: 2px #03141f;
  border-radius: 1.5px;
  justify-content: stretch;
}

.user-video-list {
  height: 100px;
  width: 100%;
  object-fit: contain;
}

.obj_name {
  background-color: rgb(5, 24, 42);
  color: snow;
  font-style: normal;
}

.obj_value {
  background-color: #cad0d4;
  font-style: normal;
  /* padding: 3px; */
}

/* .table_set {
      padding-left: 3px;
  } */
/* .main-contain {
      margin-left: 5px;
  } */

.over {
  overflow: overlay;
}

.obj_head {
  font-size: 20px;
  text-align: center;
  font-weight: bold;
}

.in-put {
  /* width: 15%; */
  border-radius: 6px #03141f;
  /* height: px; */
  padding: 0px 1px;
  font-size: 13px;
  padding-right: 1px;
  text-align: center;
  /* width: 35px; */
  width: 100%;
}

.mid {
  text-align: center;
}

.bck {
  background-color: #000;
  color: #fff;
  --bs-gutter-x: 0rem;
}

.mybt {
  background: #eee;
  /* padding: 0px 5px; */
  border: none;
  border-radius: 1px;
  margin: 0px 2px;
  font-size: 14px;
  cursor: pointer;
}

.tp {
  padding-top: 5px;
}

.rcd_count {
  padding: 5px;
}

.spfont {
  font-size: 13px;
  padding: 3px;
}

.val_pad {
  padding: 3px !important;
  font-size: 17px;
  white-space: nowrap;
}

.val_under {
  text-decoration: underline;
}

.bt_padd {
  align-content: center;
  font-weight: bold;
  padding-bottom: 6px;
}

/* .img {
      width: 10%;
  } */

.filter-ic {
  padding-left: 10px;
  padding-top: 7px;
}

.list {
  /* position: fixed; */
  display: flex;
  display: inline;
  font: menu;
  position: relative;
}

.inp {
  align-content: center;
  border-radius: 4px;
  margin: 5px;
  font-size: 14px;
}

.inp_btn {
  margin: -49px;
  padding: 0px;
  margin-top: 14px;
}

.selct_opt {
  border-radius: 4px;
  padding: 6px;
  margin: 5%;
  font-size: 15px;
  min-width: 130px;
  padding-left: 12px;
}

.inpt_type {
  border-radius: 6px;
  padding: 6px;
  margin: 5%;
  font-size: 15px;
  /* min-width: 130px;
      padding-left: 12px; */
}

.padbtnnn {
  margin: 7px;
  /* padding-left: 23px; */
  font-size: medium;
  margin-left: -32px;
}

.cancelbtn {
  margin: 7px;
  /* padding-left: 23px; */
  font-size: medium;
  padding-left: 17px;
}

.btnnn {
  /* padding: 0px 5px; */
  border: none;
  border-radius: 1px;
  margin: 0px 2px;
  font-size: 9px;
}

.addandcancel {
  margin-left: 30px;
  font-variant: all-small-caps;
  font-size: 18px;
  /* margin-left: 12px; */
}

.sub-btn {
  /* font-size: 10px; */
  margin-left: 27px;
  margin-bottom: 5px;
}

.icupdown {
  visibility: hidden;
}

.icpadding {
  margin-left: 11px;
}

.vlpointer {
  cursor: pointer;
}

.cog_pd {
  padding-left: 5px;
  padding-right: 5px;
}

.heading_top {
  font-size: 40;
  text-align: center;
  font-style: oblique;
  border: 2px #03141f;
  border-radius: 1.5px;
  justify-content: stretch;
}

.obj_name {
  background-color: rgb(5, 24, 42);
  color: snow;
  font-style: normal;
}

.obj_value {
  background-color: #cad0d4;
  font-style: normal;
  /* padding: 3px; */
}

/* .table_set {
        padding-left: 3px;
    } */
/* .main-contain {
        margin-left: 5px;
    } */

.over {
  overflow: overlay;
  overscroll-behavior: none;
  /* height: 90vh; */
}

/* .obj_head {
  font-size: 20px;
  text-align: center;
  font-weight: bold;
  margin: 0;
  text-transform: uppercase;
} */

.overfl-tab {
  flex-grow: 1;
  overflow-x: auto;
  overflow-y: auto;
  /* max-width: 75vw; */
  max-height: 95vh;
}

.in-put {
  /* width: 15%; */
  /* border-radius: 6px #03141f; */
  /* height: px; */
  min-width: 40px;
  max-width: fit-content;
  padding: 0px 1px;
  font-size: 13px;
  padding: 3px;
  text-align: center;
  background-color: #fff;
  color: #000;
  /* height: 100%; */
}

.mid {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}

.bck {
  background-color: #000;
  color: #fff;
  align-content: center;
  --bs-gutter-x: 0rem !important;
  padding-inline: 5px;
  display: flex;
  flex-wrap: wrap;
}

.mybt {
  background: #eee;
  /* padding: 0px 5px; */
  border: none;
  border-radius: 1px;
  margin: 0px 2px;
  font-size: 14px;
  cursor: pointer;
}

.tp {
  padding-top: 5px;
}

.rcd_count {
  padding: 5px;
}

.spfont {
  font-size: 13px;
  padding: 3px;
}

.val_pad {
  padding: 3px;
  font-size: 17px;
  white-space: nowrap;
}

.val_under {
  text-decoration: underline;
}

.bt_padd {
  align-content: center;
  font-weight: bold;
  padding: 5px !important;
  display: flex;
  justify-content: end;
  align-items: center;
  /* display: flex;
      justify-content: center;
      align-items: center;
      margin: 0; */
}

/* .img {
        width: 10%;
    } */

.filter-ic {
  display: flex;
  padding: 5px !important;
  text-align: start;
  align-items: center;
}

.list {
  /* position: fixed; */
  display: flex;
  display: inline;
  font: menu;
  position: relative;
}

/* .srch {
  line-height: 0.8em !important;
  width: 30% !important;
  display: inline !important;
  padding: 0.1em 0.5em !important;
  margin-left: 0.5em !important;
} */

.srch {
  line-height: 1.5em !important;
  width: 30% !important;
  display: inline !important;
  padding: 0em 0.5em 0.1rem !important;
  margin-left: 0.5em !important;
}

.inp {
  align-content: center;
  border-radius: 4px;
  margin: 5px;
  font-size: 14px;
}

.inp_btn {
  margin: -49px;
  padding: 0px;
  margin-top: 14px;
}

.selct_opt {
  border-radius: 4px;
  padding: 6px;
  margin: 5%;
  font-size: 15px;
  min-width: 130px;
  padding-left: 12px;
}

.inpt_type {
  border-radius: 6px;
  padding: 6px;
  margin: 5%;
  font-size: 15px;
  /* min-width: 130px;
        padding-left: 12px; */
}

/* .padbtnnn {
      margin: 7px;
      padding-left: 23px;
      font-size: medium;
      margin-left: -32px;
    } */

.cancelbtn {
  margin: 7px;
  /* padding-left: 23px; */
  font-size: medium;
  /* padding-left: 17px; */
  align-self: center;
}

.btnnn {
  /* padding: 0px 5px; */
  border: none;
  border-radius: 1px;
  margin: 0px 2px;
  /* font-size: 9px; */
}

.addandcancel {
  /* margin-left: 30px; */
  /* font-variant: all-small-caps; */
  font-size: flex;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 18px;
  /* margin-left: 12px; */
}

.sub-btn {
  /* font-size: 10px; */
  height: 64% !important;
  margin-left: 27px;
  margin-bottom: 5px;
}

.icupdown {
  visibility: hidden;
}

.icpadding {
  margin-left: 11px;
}

.vlpointer {
  cursor: pointer;
}

.cog_pd {
  padding-left: 5px;
  padding-right: 5px;
}

.csm_btn {
  line-height: 1.4em;
  width: auto;
  border-radius: 5px;
  font-size: var(--primary-btn-size);
  font: var(--primary-font);
}

.csm_btn_pri {
  color: var(--primary-btn-color);
  background-color: var(--primary-btn-bg-color);
  border: 1px solid var(--primary-btn-bg-color);
}

.sub-btn {
  margin-left: 0.5em;
}

.martop10 {
  margin-top: 10px;
}

.mod-ht {
  /* height: 75vh; */
  overflow: auto;
}

.namelist-mob {
  line-height: 0.8em !important;
  width: auto !important;
  display: inline !important;
}

.namelist {
  line-height: 0.8em !important;
  width: auto !important;
  height: 60% !important;
  display: inline !important;
}

.pdtop5 {
  padding-top: 5px;
}

@media only screen and (max-width: 800px) {
  .over {
    /* padding-bottom: 60px !important; */
    overflow: auto;
    height: 80vh;
  }

  .overfl-tab {
    flex-grow: 1;
    /* display: flex; */
    overflow-x: auto;
    overflow-y: hidden;
    max-width: 100vw;
    max-height: 95vh;
  }

  .bt_padd {
    justify-content: center;
  }

  .filter-ic {
    font-size: 20px;
    justify-content: space-around;
  }
}

@media only screen and (max-width: 768px) {
  .in-put {
    width: 15%;
    border-radius: 5px;
    /* height: px; */
    padding: 0px 1px;
    font-size: 12px;
    padding: 3px;
    text-align: center;
    background-color: #fff;
    color: #000;
    /* height: 100%; */
  }

  .sub-btn {
    width: 20%;
    font-size: 17px;
    height: 80% !important;
    /* margin-left: 27px; */
    margin-bottom: 5px;
}
.martop10 {
  margin-top: 10px;
  text-align: end;
}
}
