.formpadd {
  padding: 3px;
  line-height: inherit;
}

.objname {
  text-align: center;
  color: var(--primary-heading);
  font-style: normal;
}

.obj_tab {
  margin-top: 15px;
}

.objthead {
  background: var(--primary-top-bar);
  /* font-family: -webkit-pictograph; */
  font-family: var(--bs-body-font-family)
}

.lom_form_control {
  /* width: 85%; */
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.lom_form_control_reference {
  /* width: 85%; */
  /* width: 60%; */
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  appearance: none;
  /* border-radius: 0.25rem; */
  border-radius: 0.25rem 0px 0px 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btnsrc-ref {
  padding: 7px !important;
}

.lom_td {
  display: flex;
  justify-content: center;
}

.lom_div_margin {
  /* margin: auto; */
  margin: 0px 8px;
}

.unverifi {
  border-color: red;
}

.lom_button {
  border-radius: 5px;
}

.s_container {
  position: absolute;
  background: white;
  border: #212529 solid 1px;
  z-index: 100;
  box-shadow: gray 5px 3px 8px;
  justify-content: start;
  margin-top: 2%;
}

.hdd {
  color: #fff;
}

.select_num {
  padding-left: 29px;
  text-align: center;
  padding-top: 11px;
}

.wdth {
  width: 75px;
}

.row-margin-top {
  margin-top: 17px;
}