.search-container {
    position: relative;
}

.search-container .fa-search {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: #aaa;
}

.mar {
    margin: 10px 10px 0px 10px;
}

.custom-header .close {
    color: red;
    font-size: 20px;
    opacity: 1;
}