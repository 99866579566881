.loading-ack {
  z-index: 25;
  width: 75%;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
}
.loading-msg.show {
  opacity: 0;
  animation: showHide 3s ease-in-out infinite;
}
@keyframes showHide {
  0%,
  100% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

.form-control-multiUpdate {
  display: inline !important;
  max-width: 300px !important;
  min-width: 100px !important;
  border: 2px solid;
}

.form-pre-helper {
  font-family: sans-serif;
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
  text-wrap: wrap;
}

.form-pre-tooltip {
  font-family: sans-serif;
  font-size: small;
  text-align: start;
  margin: 0px;
}

.loading-overlay {
  position: absolute;
  background-color: #c3c0c0;
  width: 100%;
  opacity: 0.5;
  cursor: not-allowed;
  z-index: 10;
}

.tip {
  background-color: #fdf3d1 !important;
  /* display: inline-block ; */
  max-width: 500px !important;
  font-size: small;
  border: #6c757d solid 1px;
  margin: 0px;
  text-wrap: nowrap !important;
}

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.name {
  font-weight: bold;
}

.type {
  font-weight: bold;
  color: gray;
}

.footer {
  font-size: 0.9em;
  color: gray;
}

@media (max-width: 600px) {

  .header,
  .content,
  .footer {
    font-size: 0.9em;
  }
}

@media (max-width: 400px) {

  .header,
  .content,
  .footer {
    font-size: 0.8em;
  }
}





.maincompo {
  /* height: 97%; */
  overflow-x: hidden;
  padding: 2% 2% 3% 2%;
  background: #fff;
  /* width: 50%; */
  align-items: center;
  padding-bottom: 8px;
}

.cursor-pointer {
  cursor: pointer;
}

.inppd {
  padding: 5px;
}


.formpadd {
  padding: 3px !important;
  line-height: inherit;
  border-radius: 4px !important;
}

.ref-inp {
  padding: 3px !important;
  line-height: inherit;
  border-radius: 5px !important;
  border-bottom-right-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

.btndivin {
  display: flex;
  text-align: right;
  justify-content: end;
  padding: 4px 20px;
}

/* .tab_head {
  justify-content: center;
  align-items: center;
  font-size: 20px;
  text-transform: capitalize;
 text-transform: uppercase;
  text-align: center;
  font-weight: bold;
} */

.tab_head {
  font-size: 20px;
  text-align: center;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sub-btn-form {
  margin-left: 0.5em !important;
}

.col-width-md {
  width: 20% !important;
}

.col-width-lg {
  width: 60% !important;
}

.formbor {
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.btnsrc {
  background: #fff;
  border: 1px solid #ced4da;
  border-left: 0px;
  padding: 5px 10px;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  color: #000;
}

.insrtbtn {
  /* font-size: 15px; */
  cursor: pointer;
  margin: 3px;
}

.btndiv {
  background-color: #000;
  color: #fff;
}

.my-modal {
  width: 90vw;
  max-width: 90vw;
}

/* input [type= ‘date’ ] {
    color: #fff;
   
 }  */

.insrtbtn2 {
  font-size: 13px;
  cursor: pointer;
  margin: 3px;
  /* margin-left: 13px; */
}

.datepointer {
  cursor: pointer;
}

/* .mndtrytru {
  color: #c83c36;
  font-size: 30px;
} */
.mndtryfals {
  color: darkgrey;
  font-size: 30px;
}

/* .mndtrytrue {
  color: #c83c36;
  font-size: inherit;
  position: relative;
  bottom: 0.2em;
  margin-right: 0.5em;
} */

.mndtryfalse {
  color: darkgrey;
  font-size: 0.7em;
  position: relative;
  bottom: 0.2em;
  margin-right: 0.5em;
}

.unverifi {
  border-color: #c83c36;
}

.mndtryfals1 {
  border-color: darkgrey;
  font-size: 30px;
}

.objpadd {
  width: 420px;
  margin-left: 14px;
  font-size: 21px;
  font-family: inherit;
  border-radius: 8px;
}

.checkpadd {
  width: 26px;
  height: 18px;
  border-radius: 2px;
  margin-bottom: 0.5em;
}

.formTop {
  padding-top: 6px;
}

.objmargin {
  font-size: 18px;
  font-family: inherit;
  display: block;
  text-align: center;
}

.formpadd_danger {
  padding: 3px;
  line-height: inherit;
  border: 1px solid #c83c36;
}

.refrcd {
  padding: 2px 5px;
  list-style-type: none;
  cursor: pointer;
  color: black;
  background: white;
  /* border-radius: 3px; */
  min-width: 250px;
}

.fmcheck {
  margin-left: -24px;
  /* margin-top: -8px; */
}

.rw {
  margin: 0px 0px 0px 0px;
  background: #ddd;
  border-top: 1px solid #bbb;
  border-right: 1px solid #bbb;
  border-left: 1px solid #bbb;
}

.btnclr {
  background: #000;
}

@media (max-width: 768px) {
  .nav-pills .nav-link.active {
    background: var(--relation-nonactive);
    color: #fff;
    border-radius: 0px;
    border-top: 1px solid #fff;
    border-right: 1px solid #fff;
    border-left: 1px solid #fff;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
  }

  .nav-pills .nav-link {
    background: var(--relation-nonactive);
    border-right: 0.5px solid #fff;
    color: #fff;
    border-radius: 0px;
  }
}

@media (min-width: 768px) {
  .nav-pills .nav-link.active {
    background-color: var(--relation-active) !important;
    color: #fff;
    border-radius: 0px;
    border-top: 1px solid #fff;
    border-right: 1px solid #fff;
    border-left: 1px solid #fff;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
  }

  .nav-pills .nav-link {
    background-color: var(--relation-nonactive) !important;
    border-right: 0.5px solid #fff !important;
    color: #fff;
    border-radius: 0px !important;
  }

  .nav-link:hover {
    color: #fff !important;
  }

}

.saved-imgs {
  display: flex;
  /* grid-template-columns: 1fr 1fr; */
  gap: 10px;
  /* border: 1px solid; */
  position: relative;
}

.rr-img-div::-webkit-scrollbar {
  display: none;
}

.rr-img-div {
  position: relative !important;
  width: -moz-fit-content;
  width: fit-content;
  width: 150px;
  height: 150px;
  object-fit: contain;
  border: 1px solid #ccc8c8;
}

.cancel-mark-div {
  position: absolute !important;
  right: 5px;
  top: 5px;
  right: -12px;
  top: -12px;
  z-index: 10;
  border: 2px solid red;
  border-radius: 50px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.user-feedback-img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.add-img-div {
  text-align: left;
  /* margin-top: 10px; */
  border: 1px solid rgb(201, 201, 201);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ourCustomized-inp {
  border: 1px solid black;
  width: 90%;
}

.ourCustomized {
  display: none;
  /* border: 1px solid black; */
  /* width: 90%; */
}


.nopad {
  padding: 0px;
  text-align: center;
}

.sf_container {
  /* position: absolute; */
  background: white;
  border: #212529 solid 1px;
  max-width: 50%;
  z-index: 100;
  box-shadow: black 5px 3px 8px;
  justify-content: start;
  max-height: 200px;
  overflow-y: scroll;
}

.side-cont {
  height: 200px;
  overflow: auto;
}

.side-list {
  cursor: pointer;
}

.side-list:hover {
  font-weight: bold;
}

.field_hd {
  color: var(--primary);
  font-weight: 500;
}

.cur {
  cursor: pointer;
}

.form-control formpadd {
  border-radius: 1px;
  border-top-width: 1px;
  border-bottom-width: 1px;
}

.form_tab {
  border: 1px solid #ddd;
  margin-top: 20px;
  background-color: #fff;
  border-radius: 3px;
}

.rel_flex {
  display: flex;
  flex-wrap: wrap;
  gap: 1px 0px;
}

.varcompo {
  padding: 10px 10px 10px 10px;
  width: 40%;
}

.bgvarcompo {
  background-color: darkgray;
}