.main-background{
background: linear-gradient(to right,rgb(6, 34, 66),rgb(255,255,255,0.4))!important;
border: 1px solid black!important;
}

.main-background2{
    background: linear-gradient(to right,rgb(255,255,255,0.4),rgb(6, 34, 66))!important;
    border: 1px solid black!important;
    }

    .formviewbackground{
        border: 2px solid white;
        border-radius:7px;
        background: rgb(6, 34, 66);
        color: white;
        font-size: 23px;
        /* background: linear-gradient(to right, rgb(127, 127, 213), rgb(134, 168, 231), rgb(145, 234, 228)); */
    }

.shadow{
    box-shadow: 0px 1.5rem 8px black;
}

.select-container{
    width:60%

}
.select-container2{
    width:95%

}
.select-container3{
    width:30%

}

.select{
    width: 100%;
    height: 42px;
    border-radius: 5px;
}

.checkbox-container{
    width: 40%;
    color: black;
}
.checkbox{
    display: flex;
    justify-content: center;
    width: fit-content;
    font-size: large;
    align-self: center;
    margin-left: 20px;
    margin-right: 20px;
}
.span{
    margin-right: 5px;
}
.input-checkbox{
    width:18px;
    height: 18px;
    align-self: center;
  
}
