body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.cursor-pointer {
	cursor: pointer;
}
.underline{
	text-decoration: underline;
}

:root {
	height: 100vh;
    overflow: hidden;
	--primary: #000;
	--primary-top-bar: #282a3a;
	--primary-heading: #fff;
	--primary-font-size: 1rem;
	--relation-active: #000;
	--relation-nonactive: #282a3a; /*#27374d; */
	--primary-font-size0-2: 0.2rem;
	--primary-font-size1-2: 1.2rem;
	--primary-font-size1-3: 1.3rem;
	--primary-font-size1-4: 1.4rem;
	--primary-font-size1-5: 1.5rem;
	--primary-font-size1-6: 1.6rem;
	--primary-font-size1-8: 1.8rem;
	--primary-font: "SourceSansPro", "Helvetica Neue", "Helvetica", Arial,
	  sans-serif;
	/*--primary-font: "Roboto";*/
	/* --primary-font: "SourceSansPro", "Helvetica Neue", "Helvetica", Arial,
	  sans-serif;*/
	  --primary-auth-theme-1: #183455;
	  --primary-auth-theme-2: #ffc107;
	--primary-btn-bg-color: #0d6efd;
	--primary-btn-color: #fff;
	--primary-btn-size: 1rem;
  }
