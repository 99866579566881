.ct{
    height: 100%;
    width: 100%!important;
    justify-content: center;
    margin: 0px!important;
}

.wd{
    width: 33.33%!important;
}

.editor{
    height: 67vh;
    overflow-y: auto;
}
.upper-box{
    display: flex;
    flex-wrap: wrap;
    height: 10%;
    justify-content: center;
    align-items: center;
}
.slct{
    width: 15vw;
}
.mg-z{
    margin: 0px!important;
}

.cont{
    height: 100%;
}

@media only screen and (max-width:800px) {
	.wd{
        width: 90%!important;
    }
	.ct{
        height: 150vh!important;
    }
    .editor {
        height: 45vh!important;
    }
    .slct{
        width: 30vw;
    }
    .m-bottom{
        margin-bottom: 50px;
    }
    .cont{
        height: 95vh!important;
        overflow-y: scroll;
    }
  }